import React from 'react';
import { Link } from "gatsby"
import SEO from "../components/seo"
import Container from  '../components/container'
import Img from "gatsby-image"
import styled from "styled-components"

const PostTemplate = (props) =>{

    console.log(props.pageContext.localImage.publicURL.slice(8,13))
    console.log(props.pageContext)
    
 
    return(
        <section> 
            <SEO title={props.pageContext.brand} description='Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings' />   
            <StyledImage fluid={props.pageContext.localImage.childImageSharp.fluid} alt={props.pageContext.brand} />  
            <Container>
                <h2>{props.pageContext.brand}</h2>
                <div dangerouslySetInnerHTML={{ __html: props.pageContext.content }}></div>
                <Link to="/brands">Go back </Link>
            </Container>
        </section> 
    )
}

const StyledImage = styled(Img)`
    height : 65vw;
    max-height: 88vh; 
    position: relative;
    margin-bottom: 3px; 
`
export default PostTemplate;